import React, { useEffect, useState } from "react";
import { getData } from "../connections";
import classes from "./AnalyticsHeader.module.css";

interface Course {
    id_course: string;
    course_name: string;
    client_logo: string;
}

const AnalyticsHeader: React.FC = () => {
    const [email, setEmail] = useState<string | null>(null);
    const [courseId, setCourseId] = useState<string | null>(null);
    const [client_logo, setLogo] = useState<string | null>(null);
    const [courseName, setCourseName] = useState<string>("Loading Course...");
    const [availableCourses, setAvailableCourses] = useState<Course[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { email, availableCourses, id_course } = await getData();
                setEmail(email);
                setAvailableCourses(availableCourses);
                setCourseId(id_course);

                const selectedCourse = availableCourses.find((course: Course) => course.id_course === id_course);
                setCourseName(selectedCourse ? selectedCourse.course_name : "Unknown Course");
                setLogo(selectedCourse?.client_logo || null);  // ✅ Ensure logo updates
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []); // Runs only on mount

    const handleLogout = () => {
        window.location.href = '/auth/logout';
    };

    const handleCourseChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCourseId = event.target.value;
        console.log('Course selected:', selectedCourseId);
    
        if (selectedCourseId) {
            const params = new URLSearchParams(window.location.search);
            params.set("id_course", selectedCourseId);
            const newUrl = `${window.location.pathname}?${params.toString()}`;
            console.log('Updated URL:', newUrl);

            window.history.replaceState({}, '', newUrl);

            setCourseId(selectedCourseId);

            const selectedCourse = availableCourses.find(course => course.id_course === selectedCourseId);
            setCourseName(selectedCourse ? selectedCourse.course_name : "Unknown Course");
            setLogo(selectedCourse?.client_logo || null);  // ✅ Update logo when course changes

            window.location.href = newUrl;
        }
    };
const headerTitle = {maxWidth: '162px'};
    return (
        
<header className={classes.header}>
    <div className={classes.headerContainer}>
<div className={classes.headerLeft}>
    {client_logo && (
        <img 
            src={`/images/client-logos/${client_logo}`} 
            alt="Client Logo" 
            className={classes.logo} 
        />
    )}
</div>
    <div className={classes.headerCenter}>
        <div className='header-title'>Powered by:</div>
        <img src="/images/client-logos/lht-logo.png" alt="LHT logo" style={headerTitle}/>
    </div>

    <div className={classes.headerRight}>
        {email && <p className={classes.userInfo}>User: <strong>{email}</strong></p>}
        <select 
            id="course-select"
            value={courseId || ""}
            onChange={handleCourseChange}
            className={classes.courseSelect}
            aria-label="Select a Course"
        >
            <option value="" disabled>Select a Course</option>
            {availableCourses.map((course) => (
                <option key={course.id_course} value={course.id_course}>
                    {course.course_name}
                </option>
            ))}
        </select>
        <button onClick={handleLogout} className={classes.logoutButton}>
            Logout
        </button>
    </div>
    </div>
    <div className={classes.courseSection}>
    <div className={classes.courseTitle}>{courseName}</div>
</div>
</header>

    );
};

export default AnalyticsHeader;
