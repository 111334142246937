// 📂 Frontend: getUserEmail Helper
export const getUserEmail = async (): Promise<string | null> => {
  //   try {
  //     const response = await fetch(`${window.location.origin}/check-authorized-emails`);
  //     if (!response.ok) throw new Error(`Error ${response.status}: ${response.statusText}`);
  
  //     const data = await response.json();
  
  //     if (data.email) {
  //       console.log(`User email: ${data.email}`);
  //       return data.email;
  //     } else {
  //       console.warn("No email found in response.");
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error fetching user email:", error);
       return null;
  //   }
   };
  
  
  // Fetch the first available `id_course` if missing in the URL
  // export const getFirstAvailableCourseId = async (): Promise<string | null> => {
  //   try {
  //     const response = await fetch(`${window.location.origin}/check-authorized-emails`);
  //     if (!response.ok) throw new Error(`Error ${response.status}: ${response.statusText}`);
  
  //     const data = await response.json();
  
  //     if (data.courses?.length > 0) {
  //       const firstCourseId = data.courses[0]; // Assuming API returns an array of authorized courses
  //       console.log(`First available id_course: ${firstCourseId}`);
  //       return firstCourseId;
  //     } else {
  //       console.warn("No courses found in response.");
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error fetching first available id_course:", error);
  //     return null;
  //   }
  // };
  
  // Helper to get `id_course` from the URL, or auto-assign one if missing
  // export const getCourseIdFromURL = async (): Promise<string | null> => {
  //   const params = new URLSearchParams(window.location.search);
  //   let courseId = params.get('id_course');
  
  //   if (!courseId) {
  //     console.warn("No `id_course` found in the URL. Fetching first available course...");
  //     courseId = await getFirstAvailableCourseId();
  
  //     if (courseId) {
  //       console.log(`Auto-assigning first available id_course: ${courseId}`);
  
  //       // Update the URL without reloading the page
  //       params.set('id_course', courseId);
  //       const newUrl = `${window.location.pathname}?${params.toString()}`;
  //       window.history.replaceState({}, '', newUrl);
  //     }
  //   }
  
  //   console.log(`Final id_course used: ${courseId}`);
  //   return courseId;
  // };
  
  // Helper to get `id_course` from the URL, or auto-assign one if missing
   export const getCourseIdFromURL = async (): Promise<string | null> => {
  //   const params = new URLSearchParams(window.location.search);
  //   let courseId = params.get('id_course');
  
  //   if (!courseId) {
  //     console.warn("No `id_course` found in the URL. Redirecting to /auth/logout...");
  
  //     // Redirect to /auth/logout if id_course is not found
  //     //window.location.href = `${window.location.origin}/auth/logout`;
       return null; // Prevent further code execution
  //   }
  
  //   console.log(`Final id_course used: ${courseId}`);
  //   return courseId;
   };
  
  // General helper function for making API requests
  // const fetchData = async (endpoint: string, courseId?: string) => {
  //   try {
  //     const id = courseId || await getCourseIdFromURL();
  //     if (!id) throw new Error("No `id_course` available for API request.");
  
  //     const url = `${window.location.origin}/${endpoint}?id_course=${id}`;
  //     console.log(`Fetching from: ${url}`);
  
  //     const response = await fetch(url);
  //     if (!response.ok) throw new Error(`Error ${response.status}: ${response.statusText}`);
  
  //     return await response.json();
  //   } catch (error) {
  //     console.error("Fetch error:", error);
  //     throw error;
  //   }
  // };
  
  
  export async function getData() {
    try {
        const response = await fetch(`${window.location.origin}/check-authorized-emails`, {
            credentials: "include", // Ensure session cookies are sent
        });
  
        if (!response.ok) throw new Error(`Error ${response.status}: ${response.statusText}`);
  
        const data = await response.json();
        return {
            email: data.email,
            availableCourses: data.courses || [],
            id_course: data.id_course, // Get session-stored id_course
        };
        console.log('data:', data);
    } catch (error) {
        console.error("Error fetching user data:", error);
        return { email: null, availableCourses: [], id_course: null };
    }
  }
  
  
  
  
  // General helper function for making API requests
  const fetchData = async (endpoint: string) => {
    try {
      const url = `${window.location.origin}/${endpoint}`;
      console.log(`Fetching from: ${url}`);
  
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Error ${response.status}: ${response.statusText}`);
  
      return await response.json();
    } catch (error) {
      console.error("Fetch error:", error);
      throw error;
    }
  };
  
  // Exported functions to fetch data
  export const getAuthorizedEmails = () => fetchData('check-authorized-emails');
  export const stat_getFirstRecord_Date = () => fetchData('launch-date');
  // Stat Block Queries
  export const stat_getAvgTime_entireCourse = () => fetchData('entireCourse');
  export const stat_getAvgTime_knowledgeChecks = () => fetchData('knowledgeCheckAverageTime');
  export const stat_getAvgTime_finalAssessment = () => fetchData('assessmentTime');
  export const stat_getAvgScore_finalAssessment = () => fetchData('assessmentScore');
  
  // Table Data Queries
  export const table_getPage_completionsPerLearnerData = () => fetchData('table');
  export const table_getModuleData = () => fetchData('module');
  export const table_getInteractionsData = () => fetchData('interactions');
  export const table_getInternalLinksData = () => fetchData('internal');
  
  // Chart Data Queries
  export const chart_getLearnersByStatus = () => fetchData('learnersInProgress');
  export const chart_getFinalAssessmentAttempts = () => fetchData('final');
  export const chart_getFinalAssessmentCompletedCount = () => fetchData('learnersCompleted');
  export const chart_learnersCompletedCount = () => fetchData('learnersCompletedCount');
  export const chart_learnersInProgressCount = () => fetchData('learnersInProgressCount');
  export const chart_definedInteractionsALL = () => fetchData('definedInteractionsALL');
  export const chart_learnerAll = () => fetchData('all');
  