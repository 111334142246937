import React, { useState, useEffect, ReactNode } from 'react';

interface AuthWrapperProps {
  children: ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [id_course, setIdCourse] = useState<string | null>(null);

  useEffect(() => {
    const checkAuthStatus = async (): Promise<void> => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const courseId = urlParams.get('id_course');
        setIdCourse(courseId);

        const response = await fetch(`/auth/status?id_course=${courseId}`, {
          credentials: 'include',
        });
  
        if (response.redirected) {
          console.log('Redirecting to:', response.url);
          window.location.href = response.url; // Navigate properly
          return;
        }
  
        if (response.status === 401) {
          console.warn('Unauthorized. Redirecting to login...');
          window.location.href = `/auth/login?returnUrl=${encodeURIComponent(window.location.href)}`;
          return;
        }
  
        const data = await response.json();
        if (data.authenticated) {
          setIsAuthenticated(true);
        } else {
          setError('User is not authenticated.');
        }
      } catch (err) {
        console.error('Authentication check failed:', err);
        window.location.href = `/auth/login`; // Redirect manually if fetch fails
      } finally {
        setIsLoading(false);
      }
    };
  
    checkAuthStatus();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4">
        <div className="bg-red-50 border border-red-200 text-red-800 rounded-lg p-4">
          <h3 className="text-lg font-semibold">Authentication Error</h3>
          <p className="mt-1 text-sm">{error}</p>
        </div>
      </div>
    );
  }

  return isAuthenticated ? (
    <>
      {/* Pass id_course to children */}
      {React.cloneElement(children as React.ReactElement<any>, { id_course })}
    </>
  ) : null;
};

export default AuthWrapper;
