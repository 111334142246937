import classes from "./StatBlock.module.css";
import {
  stat_getAvgTime_entireCourse,
  stat_getAvgTime_knowledgeChecks,
  stat_getAvgTime_finalAssessment,
  stat_getAvgScore_finalAssessment,
  stat_getFirstRecord_Date,
} from "../../connections";
import { useEffect, useState } from "react";

function msToTime(duration: number): string {
  const milliseconds = Math.floor((duration % 1000) / 100);
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const hoursStr = hours < 10 ? "0" + hours : hours.toString();
  const minutesStr = minutes < 10 ? "0" + minutes : minutes.toString();
  const secondsStr = seconds < 10 ? "0" + seconds : seconds.toString();

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

const StatBlock = (Props: { statName: string; title?: string; className?: string; showFinalAssessment?: boolean; showKnowledgeCheckAssessment?: boolean }) => {
  const [stat, setStat] = useState<string>("Temp");

  console.log("StatBlock Props:", Props);
  console.log("Final Assessment show condition:", Props.showFinalAssessment);

  const getStatData = async () => {
    let data;
    let returnValue;

    switch (Props.statName) {
      case "Entire Course":
        data = await stat_getAvgTime_entireCourse();
        returnValue = msToTime(data.data[0].average_course_time);
        setStat(returnValue);
        break;
      case "Knowledge Checks":
        data = await stat_getAvgTime_knowledgeChecks();
        returnValue = msToTime(data.data[0].average_knowledge_checks_time);
        setStat(returnValue);
        break;
      case "Final Assessment":
        if (!Props.showFinalAssessment) return;
        data = await stat_getAvgTime_finalAssessment();
        returnValue = msToTime(data.data[0].average_final_assessment_time);
        setStat(returnValue);
        break;
      case "First Activity":
        try {
          const data = await stat_getFirstRecord_Date();
          console.log("First Activity Data:", data);

          if (data && data.first_launch_date) {
            const dateLaunch = new Date(data.first_launch_date).toLocaleDateString('en-US');
            returnValue = dateLaunch;
          } else {
            returnValue = "No activity found";
          }
          setStat(returnValue);
        } catch (error) {
          console.error("Error fetching first activity date:", error);
          setStat("Error fetching data");
        }
        break;

      case "*":
        data = await stat_getAvgScore_finalAssessment();
        returnValue = data.data[0].average_score;
        setStat(returnValue);
        break;
    }
  };

  useEffect(() => {
    setStat("Loading...");
    getStatData();
  }, [Props.statName, Props.showFinalAssessment, Props.showKnowledgeCheckAssessment]);

  if (!Props.showFinalAssessment && Props.statName === "Final Assessment") return null;

  if (!Props.showKnowledgeCheckAssessment && Props.statName === "Knowledge Checks") return null;

  return (
    <div className={`${classes.statContainer} ${Props.className}`}>
      {Props.title && <p className={classes.title}>{Props.title}</p>}
      <div className={classes.innerContainer}>
        {Props.statName.length > 1 && <p className={classes.name}>{Props.statName}</p>}
        <p className={classes.stat}>{stat}</p>
      </div>
    </div>
  );
};

export default StatBlock;
