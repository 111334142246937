// Update AnalyticsTable.tsx
import { useEffect, useState } from "react";
import "./App.css";
import Chart from "./components/Chart/Chart";
import Table from "./components/Table/Table";
import StatBlock from "./components/StatBlock/StatBlock";
import AnalyticsHeader from "./components/AnalyticsHeader";
import ChartTotalLearners, {
	studentLayout,
	attemptLayout,
	studentData,
	attemptData,
} from "./components/ChartTotalLearners/ChartTotalLearners";

const AnalyticsTable = () => {
	const [showFinalAssessment, setShowFinalAssessment] = useState<boolean>(false);
	const [showKnowledgeCheck, setShowKnowledgeCheck] = useState<boolean>(false);

	useEffect(() => {
		const fetchFinalAssessmentStatus = async () => {
		  try {
			const urlParams = new URLSearchParams(window.location.search);
			const id_course = urlParams.get('id_course');
	  
			if (!id_course) return;
	  
			const response = await fetch(`/assessmentTime?id_course=${id_course}`);
			const data = await response.json();
	  
			if (data?.enabled !== undefined) {
			  setShowFinalAssessment(data.enabled);
			}
		  } catch (error) {
			console.error("Error fetching final assessment status:", error);
		  }
		};

		const fetchKnowledgeCheckStatus = async () => {
			try {
			  const urlParams = new URLSearchParams(window.location.search);
			  const id_course = urlParams.get('id_course');
		
			  if (!id_course) return;
		
			  const response = await fetch(`/knowledgeCheckAverageTime?id_course=${id_course}`);
			  const data = await response.json();
		
			  if (data?.enabled !== undefined) {
				setShowKnowledgeCheck(data.enabled);
			  }
			} catch (error) {
			  console.error("Error fetching final assessment status:", error);
			}
		};
	  
		fetchFinalAssessmentStatus();
		fetchKnowledgeCheckStatus();
	}, []);	  

	return (
		<div className="App">
			<AnalyticsHeader />
			{/* <div className="headerBar">
				<img src="jnj.png" alt="" />
			</div> */}
			<div className="grid">
				<div className="row1">
					<ChartTotalLearners graphName="StudentGraph" layout={studentLayout} data={studentData} />
					<Table tableName="completions" />
					<Table tableName="interactions" />
				</div>
				<div className="row2">
					<div className="statGroup">
						<StatBlock title="Average Time to Completion" statName="Entire Course" />
						<StatBlock statName="Knowledge Checks" showKnowledgeCheckAssessment={showKnowledgeCheck}/>
						<StatBlock statName="Final Assessment" showFinalAssessment={showFinalAssessment} />
						<StatBlock title= "First Recorded Activity For Course" statName="First Activity" />
					</div>
					<StatBlock className="statBottom" title="Avg Score" statName="*" />
				</div>
				<div className="row3">
					<Table tableName="module" />
					<Table tableName="internal" />
					{showFinalAssessment && <ChartTotalLearners graphName="AttemptsGraph" layout={attemptLayout} data={attemptData} />}
				</div>
			</div>
		</div>
	);
};

export default AnalyticsTable;
